<template>
  <div class="bb-1">
    <div class="grid mt-1" style="height: 60px">
      <RouterLink :to="'/'" class="absolute">
        <img class="logo" src="@/assets/logo.png" alt="logo" />
      </RouterLink>
      <div class="col uppercase text-xl">Điện lạnh Trần Thiên</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import { RouterLink } from "vue-router";

interface Cate {
  id: number;
  name: string;
}

export default defineComponent({
  name: "HeaderComponent",
  components: { RouterLink },
  props: {
    // cateList: {
    //   type: Array,
    //   required: false,
    // },
  },
  emits: ["onSearch"],

  setup() {
    const keySearch = ref<string>("");
    const isPhone = ref<boolean>(false);
    const updateIsPhone = () => {
      isPhone.value = window.innerWidth < 768;
    };

    onMounted(() => {
      window.addEventListener("resize", updateIsPhone);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", updateIsPhone);
    });

    const cateList: Cate[] = [
      { id: 1, name: "Điện thoại" },
      { id: 2, name: "Laptop" },
      { id: 3, name: "Máy ảnh" },
      { id: 4, name: "Máy lạnh" },
      { id: 5, name: "Máy giặt" },
    ];

    return {
      cateList,
      keySearch,
      isPhone,
    };
  },
});
</script>

<style scoped>
/* var height */
.logo {
  display: block;
  aspect-ratio: 1;
  max-height: 60px;
  height: 100%;
}
</style>
