import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = { class: "bb-1" }
const _hoisted_2 = {
  class: "grid mt-1",
  style: {"height":"60px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_RouterLink, {
        to: '/',
        class: "absolute"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            class: "logo",
            src: _imports_0,
            alt: "logo"
          }, null, -1)
        ])),
        _: 1
      }),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col uppercase text-xl" }, "Điện lạnh Trần Thiên", -1))
    ])
  ]))
}