<template>
  <div>
    <h4>Liên hệ</h4>
    <div>Zalo:</div>
    <div>Facebook:</div>
  </div>
  <div class="mt-4">Điện lạnh Trần Thiên</div>
  <div>Vũ Đức Triệu &copy; 2024</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FooterComponent",
});
</script>
