import axios, { AxiosResponse, AxiosRequestConfig } from "axios";

class ApiUtils {
  private static BASE_URL: string;

  public static init(baseUrl: string): void {
    ApiUtils.BASE_URL = baseUrl;
  }

  public static async get(url: string): Promise<AxiosResponse> {
    return axios.get(this.BASE_URL + url);
  }

  public static async post(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return axios.post(this.BASE_URL + url, data, config);
  }

  public static async put(url: string, data?: unknown): Promise<AxiosResponse> {
    return axios.put(this.BASE_URL + url, data);
  }

  public static async delete(url: string): Promise<AxiosResponse> {
    return axios.delete(this.BASE_URL + url);
  }
}

export default ApiUtils;
