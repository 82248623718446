import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", null, [
      _createElementVNode("h4", null, "Liên hệ"),
      _createElementVNode("div", null, "Zalo:"),
      _createElementVNode("div", null, "Facebook:")
    ], -1)),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "mt-4" }, "Điện lạnh Trần Thiên", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("div", null, "Vũ Đức Triệu © 2024", -1))
  ], 64))
}