import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import PrimeVue from "primevue/config";
import "primeflex/primeflex.min.css";
import "primeicons/primeicons.css";
import Aura from "@primevue/themes/aura";
import store from "@/store";
import ApiUtils from "@/utils/apiUtils";

declare global {
  interface Window {
    __API_URL__: string;
  }
}

ApiUtils.init(
  window.__API_URL__ ? window.__API_URL__ : "http://localhost:8081/api"
);

const app = createApp(App);
app.use(router);
app.use(PrimeVue, { theme: { preset: Aura } });
app.use(store);
app.mount("#app");
