<template>
  <HeaderComponent />
  <router-view />
  <FooterComponent />
</template>

<script lang="ts">
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseView",
  components: { HeaderComponent, FooterComponent },
});
</script>
