import ProductDto from "@/dto/productDto";
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    productList: [
      // Add more products as needed
    ],
    searchList: {
      brand: [],
      price: [],
      power: [],
      type: [],
      keySearch: "",
    },
    productSelected: {} as ProductDto,
  },
  mutations: {
    setProductList(state, productList) {
      state.productList = productList;
    },
    setSearchList(state, searchList) {
      state.searchList = searchList;
    },
    setProductSelected(state, productSelected) {
      state.productSelected = productSelected;
    },
  },
  actions: {
    // Define your actions here
  },
  getters: {
    getProducts: (state) => state.productList,
    getSearchList: (state) => state.searchList,
    getProductSelected: (state) => state.productSelected,
  },
  plugins: [createPersistedState()],
});
